import {
  Box,
  Stack,
  Typography,
  Button,
  capitalize,
  Container,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isObjectEmpty, primaryBgc } from "../utils/jsutils";
import { ExpandMore } from "@mui/icons-material";
import {
  deleteServicedesk,
  loadServicedesk,
  selectServicedeskStatus,
} from "../state/features/servicedesk/servicedesk";
import { useSnackbar } from "notistack";
import { ServicedeskApi } from "../apis/servicedeskApi";
import { useKeycloak } from "keycloak-react-web";
import TestDialogTemp from "../state/features/servicedesk/TestDialogTemp";
import {
  selectActiveUserId,
  selectUserData,
} from "../state/features/user/user";
import { selectBondsData } from "../state/features/bonds/bonds";
import ServiceDeskStepper from "../state/features/servicedesk/ServiceDeskStepper";

/**
 * Service Desk Page component
 *
 * Renders a page with a servicedesk configuration, if available
 * If no servicedesk configuration is available, it displays a prompt to connect one
 */
function ServiceDeskPage() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { initialized } = useKeycloak();
  const dispatch = useDispatch();
  const servicedesk = useSelector((state) => state.servicedesk);
  const servicedeskStatus = useSelector(selectServicedeskStatus);
  const activeSubscriberId = useSelector(selectActiveUserId);

  /**
   * Servicedesk card menu operations
   */

  const getsd = async () => {
    let sd = await ServicedeskApi.getServicedesk();
    return sd;
  };

  useEffect(() => {
    if (
      initialized &&
      servicedeskStatus === "idle" &&
      isObjectEmpty(servicedesk.configuration)
    ) {
      dispatch(loadServicedesk(activeSubscriberId));
    }
  }, [initialized, servicedesk, servicedeskStatus]);

  // Selector hook to get the servicedesk configuration from the redux store
  const handleDelete = () => {
    dispatch(deleteServicedesk(activeSubscriberId));
    enqueueSnackbar("Service desk configuration deleted");
  };

  /**
   * Renders a prompt to connect a servicedesk when none is found
   */
  const noServicedeskFound = () => (
    <div
      style={{
        backgroundImage: `url(images/no_sd.svg)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "calc(100vh - 60px)",
        width: "100%",
        justifyContent: "center",
        // alignItems: "center",
        display: "flex",
      }}
    >
      <Stack
        sx={{ marginTop: { xs: "5%", sm: 10, md: 15, lg: 15, xl: 15 }, p: 2 }}
      >
        <Typography
          sx={{
            typography: { xs: "h5", sm: "h4", md: "h4", lg: "h4", xl: "h4" },
          }}
          color="primary"
        >
          Don't have a service desk set up?
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "start",
              sm: "start",
              md: "end",
              lg: "end",
              xl: "end",
            },
          }}
        >
          <ServiceDeskStepper />
        </Box>
      </Stack>
    </div>
  );

  // Content to be rendered
  let content;
  let product;
  if (servicedesk.configuration.formData)
    product = servicedesk.configuration.formData.client;
  // Check if the servicedesk configuration from the redux store is empty
  if (isObjectEmpty(servicedesk.configuration)) {
    content = noServicedeskFound();
  } else {
    content = (
      <Container sx={{ p: 5 }}>
        {product && (
          <>
            <Paper sx={{ backgroundColor: primaryBgc, p: 2 }} elevation={0}>
              <Typography
                variant="body1"
                color="primary"
                sx={{ fontWeight: 800, p: 1 }}
              >
                We're working to get your {capitalize(product)} up and running!
              </Typography>

              <Accordion sx={{ m: 1 }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={`images/${product}.svg`}
                    alt={product + " logo"}
                    width={30}
                  />
                  <Typography sx={{ marginLeft: 3 }}>
                    Request details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <pre>
                      {JSON.stringify(servicedesk.configuration, null, 2)}
                    </pre>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Paper>
            <Box sx={{ display: "flex", justifyContent: "end", p: 1 }}>
              <Button
                onClick={handleDelete}
                variant="contained"
                color="secondary"
              >
                Delete
              </Button>
            </Box>
          </>
        )}
      </Container>
    );
  }

  return <div id="servicedesk-config-page">{content}</div>;
}

export default ServiceDeskPage;
