import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useKeycloak } from "keycloak-react-web";
import { useNavigate } from "react-router-dom";
import {
  selectUserData,
  selectActiveUserId,
  setActiveSubscriber,
} from "../state/features/user/user";
import {
  selectBondsData,
  selectBondsStatus,
  selectBond,
  loadBonds,
} from "../state/features/bonds/bonds";
import { loadContainers } from "../state/features/container/container";
import { loadAudit } from "../state/features/audit/audit";
import {
  Box,
  Paper,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  ListItemText,
  ListSubheader,
  darkScrollbar,
} from "@mui/material";
import {
  AccountCircleOutlined,
  LogoutOutlined,
  AccountCircle,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";
import { getSubscriberNameById } from "../utils/userUtils";
import { primaryBlue } from "../utils/jsutils";

function SubscribersDropdownMenu() {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const activeSubscriberId = useSelector(selectActiveUserId);
  const bonds = useSelector(selectBondsData);
  const bondsStatus = useSelector(selectBondsStatus);

  const subscribersMenuItems = () => {
    if (user.subscribers && user.subscribers.length > 0) {
      let menuItems = [];

      user.subscribers.forEach((subscriber) => {
        menuItems.push(
          <MenuItem
            key={subscriber.id}
            onClick={() => handleChangeActiveUser(subscriber.id)}
            dense
          >
            <ListItemIcon>
              {isActiveUser(subscriber.id) ? (
                <AccountCircle color="secondary" />
              ) : (
                <AccountCircleOutlined color="primary" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontWeight: isActiveUser(subscriber.id) ? 600 : 500,
                  }}
                >
                  {subscriber.name}
                </Typography>
              }
            />
          </MenuItem>
        );
      });
      return menuItems;
    } else {
      return <Typography variant="caption">No subscribers yet.</Typography>;
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getActiveUserName = () => {
    if (user.subscribers) {
      return getSubscriberNameById(user.subscribers, activeSubscriberId);
    } else {
      return "Unknown";
    }
  };

  const handleChangeActiveUser = async (id) => {
    localStorage.setItem("localSubscriberId", id);
    dispatch(setActiveSubscriber(id));
    dispatch(selectBond(null));
    dispatch(loadBonds(id));
    dispatch(loadAudit(id));
    dispatch(loadContainers(id));
    handleClose();
  };

  const isActiveUser = (id) => {
    return id === activeSubscriberId;
  };

  const handleLogout = async () => {
    try {
      navigate("/", { replace: true }); // Redirect to the home page
      await keycloak.logout();
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  return (
    <div id="user menu">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: primaryBlue,
          p: 0.5,
          borderRadius: "999em",
          transition: "background-color 0.3s",
          "&:hover": {
            backgroundColor: "#305f99",
          },
        }}
        onClick={handleMenu}
      >
        <Paper
          elevation={0}
          sx={{
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AccountCircle color="secondary" fontSize="small" />
        </Paper>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
            color: "white",
            marginLeft: 1,
            marginRight: 1,
          }}
        >
          {getActiveUserName()}
        </Typography>
        <Paper
          variant="contained"
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "none",
          }}
        >
          {Boolean(anchorEl) ? (
            <ArrowDropUp fontSize="small" sx={{ color: "white" }} />
          ) : (
            <ArrowDropDown fontSize="small" sx={{ color: "white" }} />
          )}
        </Paper>
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ className: "custom-border-override" }}
      >
        <ListSubheader sx={{ lineHeight: 2 }}>Subscribers</ListSubheader>
        {subscribersMenuItems()}
        <Divider></Divider>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}

export default SubscribersDropdownMenu;
