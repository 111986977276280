import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useKeycloak } from "keycloak-react-web";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Container,
  Grow,
  Button,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BondsPanel from "../state/features/bonds/BondsPanel";
import AuditPanel from "../state/features/audit/AuditPanel";
import { primaryBlue, primaryBgc, secondary } from "../utils/jsutils";
import CustomerProfiles from "./components/CustomerProfiles";
import SupportedProductsAnimation from "./components/SupportedProductsAnimation";
import FaciligateCustomers from "./components/FaciligateCustomers";
import ProductPage from "./ProductPage";

function HomePage() {
  const { initialized, keycloak } = useKeycloak();
  const [grow, setGrow] = useState(false);

  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setTimeout(() => {
      setGrow(true);
    }, 800);
  }, [initialized, keycloak]);

  let pillars = [
    {
      title: "Bond Quickly",
      content: [
        "Integrate any service management tools in hours. Not days or weeks. No expensive consultants or custom code.",
        "And after you’ve done it, new cross company bonds are easy thanks to 1Bonding’s unique normalization process. You’ll be more efficient, reduce on-boarding time, ensure better service and lower cost.",
      ],
      link: "Schedule a demo",
      svg: "Clock.svg",
    },
    {
      title: "Bond Everywhere",
      content: [
        "Remove the barriers to creating cross company service management integrations. Simply connect to 1Bonding and your secure broker enables you to bond with as many external parties as you need.",
        "You’ll all have full control of what tickets, incidents, and requests are shared, always. And what you share appears local on both sides of the bond, no matter what tools are used.",
      ],
      link: "Let's talk",
      svg: "Globe.svg",
    },
    {
      title: " Bond Reliably",
      content: [
        "1Bonding ensures the successful delivery of the service management data that you need to provide outstanding service and shorter resolution times.",
        "All transactions are recorded in an audit trail so you can identify problems before they impact customer satisfaction. You’ll also have a test environment so you can check your set-up to be certain it works as expected.",
      ],
      link: "Contact us",
      svg: "Shield.svg",
    },
  ];

  return (
    <div>
      <Box
        id="home-page"
        sx={{
          flexDirection: "column",
          backgroundColor: keycloak.authenticated ? "white" : primaryBgc,
        }}
      >
        {keycloak.authenticated && (
          <Box component="main" sx={{ marginTop: 2 }}>
            <Box sx={{ margin: "auto", width: "95%", mb: 5 }}>
              <BondsPanel />
            </Box>
            <Container maxWidth="xl">
              <AuditPanel />
            </Container>
          </Box>
        )}

        {!keycloak.authenticated && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                pt: {
                  xl: "5em",
                  lg: "5em",
                  md: "6em",
                  sm: "2.5em",
                  xs: "2.5em",
                },
                pb: {
                  xl: "5em",
                  lg: "5em",
                  md: "6em",
                  sm: "2.5em",
                  xs: "2.5em",
                },
              }}
            >
              <Stack
                sx={{
                  display: {
                    xs: "flex",
                    sm: "none",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  overflow: "hidden",
                }}
              >
                <img
                  src="images/hero.png"
                  style={{
                    width: "140%",
                    maxWidth: 600,
                    position: "relative",
                    top: 0,
                    left: -25,
                    paddingBottom: 5,
                  }}
                  alt="1bonding hero"
                />
                <Box sx={{ pl: 3, pr: 3 }}>
                  <Typography
                    sx={{
                      fontWeight: 800,
                      textTransform: "none",
                      marginTop: 0,
                    }}
                    variant="h4"
                    color="primary"
                  >
                    IT service integrations
                  </Typography>
                  <Grow in={grow}>
                    <Typography
                      variant="h4"
                      color="secondary"
                      sx={{
                        fontWeight: 800,
                        textTransform: "none",
                        marginBottom: -1.3,
                      }}
                    >
                      evolved.
                    </Typography>
                  </Grow>
                  <Typography
                    color="primary"
                    sx={{ marginTop: 2, fontSize: "1.2rem", lineHeight: 1 }}
                  >
                    Integrate faster, easier and more reliably than ever before.
                    Then get back to doing what you do best.
                  </Typography>
                  <Button
                    color="secondary"
                    variant="contained"
                    component={NavLink}
                    to="/contact-us"
                    sx={{
                      textTransform: "none",
                      marginTop: 2,
                      color: primaryBlue,
                      fontWeight: 800,
                    }}
                  >
                    Start your journey
                  </Button>
                </Box>
              </Stack>

              <Grid
                container
                alignItems={"center"}
                spacing={2}
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              >
                <Grid
                  item
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    position: "relative",
                    top: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src="images/hero.png"
                    style={{
                      width: "100%",
                      maxWidth: 800,
                      position: "relative",
                      top: 0,
                      left: -20,
                    }}
                    alt="1bonding hero"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box
                    sx={{
                      width: { xl: 600, lg: 590, md: 450, sm: 500, xs: 300 },
                      pl: { xl: 0, lg: 0, md: 0, sm: "2em", xs: "2em" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 800,
                        textTransform: "none",
                      }}
                      variant="h3"
                      color="primary"
                    >
                      IT service integrations
                    </Typography>
                    <Grow in={grow}>
                      <Typography
                        variant="h3"
                        color="secondary"
                        sx={{
                          fontWeight: 800,
                          textTransform: "none",
                          marginBottom: -1.3,
                        }}
                      >
                        evolved.
                      </Typography>
                    </Grow>
                    <Typography
                      color="primary"
                      variant="h5"
                      sx={{ marginTop: 2 }}
                    >
                      Exchange service requests across company borders faster,
                      easier, and more reliably than ever before. Then get back
                      to doing what you do best.
                    </Typography>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      component={NavLink}
                      to="/contact-us"
                      sx={{
                        textTransform: "none",
                        marginTop: 3,
                        color: primaryBlue,
                        fontWeight: 800,
                        fontSize: "1.1rem",
                      }}
                    >
                      Start your journey
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              id="subheader-container"
              sx={{
                display: "flex",
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
                width: "100%",

                backgroundColor: primaryBgc,
                pb: { xs: "5em", sm: "3em", md: "4em", lg: "4em", xl: "4em" },
                overflow: "clip",
              }}
            >
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "80%",
                    md: "80%",
                    sm: "100%",
                    xs: "100%",
                  },
                  backgroundColor: primaryBlue,
                  borderRadius: smallscreen ? "0 4em 0 0" : "0 6em 0 0 ",
                  display: "flex",
                  height: "80%",
                  pl: { xs: "0", sm: "0", md: "2em", lg: "4em", xl: "4em" },
                  overflow: "clip",
                }}
              >
                {" "}
                <Stack
                  sx={{
                    // marginTop: { xs: 1, sm: 2, md: 0 },
                    width: {
                      xl: 630,
                      lg: 630,
                      md: "60%",
                      sm: "80%",
                      xs: "90%",
                    },
                    pb: {
                      xl: "10em",
                      lg: "8em",
                      md: "6em",
                      sm: "8em",
                      xs: "8em",
                    },
                    pt: { xl: 10, lg: 13, md: 10, sm: 8, xs: 2 },
                    pl: { xl: 5, lg: 5, md: 2, sm: 5, xs: 2 },
                  }}
                  spacing={2}
                >
                  <Typography
                    color="secondary"
                    sx={{
                      fontWeight: 800,
                      fontSize: smallscreen ? "1.3rem" : "2rem",
                      marginTop: 1,
                      marginBottom: 1,
                    }}
                  >
                    No access? No problem!
                  </Typography>
                  <Typography
                    color="white"
                    sx={{
                      fontSize: smallscreen ? "1rem" : "1.3rem",
                      fontWeight: 600,
                    }}
                  >
                    With 1Bonding, initiating a secure ticket exchange doesn't
                    require a lengthy access request process.
                  </Typography>
                  <Typography
                    color="white"
                    sx={{
                      fontSize: smallscreen ? "1rem" : "1.3rem",
                      fontWeight: 600,
                    }}
                  >
                    To exchange tickets with another company, you invite them to
                    join the 1Bonding SaaS service. They receive a welcome pack
                    to set up secure ticket exchange. Each 1Bonding subscriber
                    configures their service desk connection and has full
                    control over what data is shared and with whom. No need to
                    request access.{" "}
                  </Typography>
                </Stack>
              </Box>
              <Box
                sx={{
                  ml: {
                    xl: "-30%",
                    lg: "-20%",
                    md: "-20%",
                    sm: "50%",
                    xs: "10%",
                  },
                  mt: { xl: "5%", lg: "10%", md: "15%", sm: "-8%", xs: "-20%" },
                }}
              >
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xl: 600, lg: 470, md: 400, sm: 300, xs: 300 },
                    overflow: "clip",
                  }}
                  elevation={0}
                >
                  <img
                    src="images/bonds_overview.jpeg"
                    width={"100%"}
                    alt="1bonding homescreen"
                  />
                </Paper>
              </Box>
            </Box>
            <Container maxWidth="md">
              <Typography
                variant="h5"
                sx={{
                  color: secondary,
                  fontWeight: 800,
                  m: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
                align="center"
              >
                Supporting a Range of Products
              </Typography>
            </Container>
            <SupportedProductsAnimation />

            <Box
              id="customer-profiles"
              sx={{
                pt: "2rem",
                scrollSnapStop: "normal",
              }}
            >
              <CustomerProfiles />
            </Box>
            <Box
              sx={{
                pt: "6rem",
                pb: "2rem",
                backgroundColor: primaryBgc,
                scrollSnapStop: "normal",
              }}
            >
              <Container maxWidth="xl">
                <Stack
                  spacing={5}
                  sx={{
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                    width: "100%",
                  }}
                >
                  {pillars.map((pillar, i) => (
                    <Box
                      key={pillar.title}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: i % 2 === 0 ? "end" : "start",
                        position: "relative",
                      }}
                    >
                      <Paper
                        key={pillar.title}
                        elevation={10}
                        sx={{
                          p: 3,
                          backgroundColor: "#07264f",
                          display: "flex",
                          overflow: "hidden",
                          justifyContent: i % 2 === 0 ? "end" : "start",
                          width: {
                            xl: 940,
                            lg: 840,
                            md: 800,
                            sm: "100%",
                            xs: "100%",
                          },
                          position: "relative",
                          marginRight: {
                            sm: 0,
                            md: i % 2 === 0 ? "5%" : 0,
                            lg: i % 2 === 0 ? "10%" : 0,
                            xl: i % 2 === 0 ? "10%" : 0,
                          },
                          marginLeft: {
                            sm: 0,
                            md: i % 2 !== 0 ? "5%" : 0,
                            lg: i % 2 !== 0 ? "10%" : 0,
                            xl: i % 2 !== 0 ? "10%" : 0,
                          },
                        }}
                      >
                        <Stack>
                          <img
                            src={"images/" + pillar.svg}
                            style={{
                              transform:
                                i % 2 === 0
                                  ? "rotate(-20deg)"
                                  : "rotate(20deg)",
                              height: smallscreen ? "90%" : "130%",
                              width: "auto",
                              opacity: 0.1,
                              position: "absolute",
                              left: smallscreen
                                ? "28%"
                                : i % 2 === 0
                                ? "-10%"
                                : "45%",
                              bottom: 0,
                              top: smallscreen ? "5%" : 0,
                            }}
                            alt="1bonding pillar"
                          />
                          <Stack
                            sx={{
                              width: {
                                xl: 600,
                                lg: 600,
                                md: 600,
                                sm: 500,
                                xs: "100%",
                              },
                              display: "flex",
                              justifyContent: i === 0 ? "start" : "end",
                            }}
                            spacing={smallscreen ? 2 : 3}
                          >
                            <Typography
                              variant={smallscreen ? "h5" : "h4"}
                              color="secondary"
                              sx={{
                                fontWeight: 800,
                              }}
                            >
                              {pillar.title}
                            </Typography>
                            {pillar.content.map((text) => (
                              <Typography
                                variant="body1"
                                sx={{ marginTop: 1 }}
                                color="white"
                                key={text}
                              >
                                {text}
                              </Typography>
                            ))}
                            <Button
                              color="secondary"
                              component={NavLink}
                              variant="contained"
                              to="/contact-us"
                              size={smallscreen ? "small" : "large"}
                              sx={{
                                fontWeight: 800,
                                marginTop: 2,
                                width: 200,
                                color: "#09264f",
                              }}
                            >
                              {pillar.link}
                            </Button>
                          </Stack>
                        </Stack>
                      </Paper>
                    </Box>
                  ))}
                </Stack>
              </Container>

              {/* <FaciligateCustomers /> */}
            </Box>
            <ProductPage />
          </Box>
        )}
      </Box>
    </div>
  );
}

export default HomePage;
