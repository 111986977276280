import { useKeycloak } from "keycloak-react-web";

export default function ProtectedRoute({ children }) {
  const { keycloak, initialized } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  if (initialized && !isLoggedIn) {
    keycloak.login();
    return null;
  }
  return children;
}
